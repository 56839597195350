import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { noop } from 'lodash'
import { HelpModal } from 'components/Help/HelpModal'
import { isIphone } from 'src/utils/isIPhone'
import { HelpDialog } from 'components/Help/HelpDialog'
import { toast } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { SEND_HELP_FEEDBACK } from 'components/Help/graphql'
import {
  sendHelpFeedbackVariables,
  sendHelpFeedback as sendHelpFeedbackMutation,
} from 'types/sendHelpFeedback'
import { useOrganisation } from 'src/context/organisation'

type Context = {
  toggleHelpModal: () => void
}

const HelpContext = createContext<Context>({
  toggleHelpModal: noop,
})
HelpContext.displayName = 'HelpContext'

type Props = {
  children: React.ReactElement
}

export const HelpContextProvider: React.FC<Props> = ({ children }) => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false)
  const [feedback, setFeedback] = useState<string>('')

  const { t } = useTranslation()
  const [{ organisationId }] = useOrganisation()

  const toggleHelpModal = useCallback(() => {
    setIsHelpModalOpen(open => !open)
  }, [])

  const onFeedbackChange = useCallback((newFeedback: string) => {
    setFeedback(newFeedback)
  }, [])

  const [sendHelpFeedback] = useMutation<
    sendHelpFeedbackMutation,
    sendHelpFeedbackVariables
  >(SEND_HELP_FEEDBACK)

  const onSendFeedback = useCallback(() => {
    sendHelpFeedback({
      variables: {
        input: {
          feedback,
          organisation_id: organisationId,
        },
      },
    })

    setFeedback('')
    toast.success(t('help:feedbackSentToastMessage'))
  }, [feedback, organisationId, sendHelpFeedback, t])

  const onCloseHelpModal = useCallback(() => {
    setIsHelpModalOpen(false)
    setFeedback('')
  }, [])

  const shouldDisableSendFeedbackButton = useMemo(() => {
    return feedback.length === 0
  }, [feedback])

  return (
    <HelpContext.Provider
      value={{
        toggleHelpModal,
      }}
    >
      {children}

      {isIphone ? (
        <HelpDialog
          toggleDialog={toggleHelpModal}
          visible={isHelpModalOpen}
          onFeedbackChange={onFeedbackChange}
          onSendFeedback={onSendFeedback}
          feedback={feedback}
          shouldDisableSendFeedbackButton={shouldDisableSendFeedbackButton}
        />
      ) : (
        <HelpModal
          visible={isHelpModalOpen}
          onClose={onCloseHelpModal}
          feedback={feedback}
          onFeedbackChange={onFeedbackChange}
          onSendFeedback={onSendFeedback}
          shouldDisableSendFeedbackButton={shouldDisableSendFeedbackButton}
        />
      )}
    </HelpContext.Provider>
  )
}

export const useHelp = () => {
  return useContext(HelpContext)
}
