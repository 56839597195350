import { BottomSheetModal } from '@gorhom/bottom-sheet'
import { useNavigation } from '@react-navigation/native'
import { VRBottomSheet } from 'components/BottomSheet'
import { SvgEnterFull } from 'components/Icons/EnterFull'
import { SvgExitFull } from 'components/Icons/ExitFull'
import { useUpdatePatientImage } from 'components/Patient/useUpdatePatientImage'
import { Patient } from 'components/PatientItem/PatientListItem'
import { SheetActionContainer } from 'components/SheetActions/SheetActionContainer'
import { useTrainingSwitch } from 'components/Training/useTrainingSwitch'
import { Clock } from 'components/common/Clock'
import { ImageUploaderDialog } from 'components/common/ImageUploader/ImageUploaderDialog'
import { SearchInput } from 'components/common/SearchInput'
import { Colors } from 'constants/Colors'
import { SCROLL_LIST_END_REACHED_THRESHOLD } from 'constants/Layout'
import { partition } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActivityIndicator,
  LayoutChangeEvent,
  ListRenderItemInfo,
  Platform,
  Pressable,
  RefreshControl,
  SectionList,
  SectionListData,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Fonts } from 'src/constants/Fonts'
import { Routes } from 'src/constants/Routes'
import { useHeaderSwitch } from 'src/context/fullScreenSwitch'
import { useOrganisation } from 'src/context/organisation'
import { useBreakpoint } from 'src/hocs/breakpoint'
import { HEADER_ERROR_HEIGHT, useAutoScroll } from 'src/hooks/useAutoScroll'
import {
  listWhiteboardPatients_listWhiteboardPatients_items_due_tasks as DueTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_missed_tasks as MissedTasks,
  listWhiteboardPatients_listWhiteboardPatients_items_tasks as PatientTask,
} from 'src/types/listWhiteboardPatients'
import { isOptimisticId } from 'src/utils/optimisticId'
import { preventClickPropagation } from 'src/utils/preventClickPropagation'

import { usePatientTasksColumns } from '../PatientItem/usePatientTasksColumns'
import { SubHeader } from '../SubHeader/SubHeader'
import { FilterClearButtons } from './FilterClearButtons'
import {
  OnPressPatientListCardParams,
  PATIENT_CARD_HEIGHT,
  PatientListCard,
} from './PatientListCard'
import { PatientListFilter } from './PatientListFilter'
import { PatientListHeader } from './PatientListHeader'
import { PatientListSort, SortOptionsEnum } from './PatientListSort'
import {
  DoFetchMore,
  PATIENT_LIST_RESULTS_LIMIT,
  PatientListData,
  useGetPatientList,
} from './useGetPatientList'
import { usePatientSubscription } from './usePatientSubscription'
import { getPatientListActionButton } from './utils/getPatientListActionButton'
import {
  filterType,
  useLocalStorageChangeFilters,
} from './utils/useLocalStorageChangeFilters'
import {
  sortType,
  useLocalStorageChangeSort,
} from './utils/useLocalStorageChangeSort'
import {
  PatientListView,
  usePatientListCursor,
} from './utils/usePatientListCursor'
import ExpirationPopup from 'components/Login/components/ExpirationPopup'

type HeaderSectionList = SectionListData<{
  patient: Patient
  resultCursor: string | null
  missedTasksCount: number
  tasks: PatientTask[]
  dueTasks: DueTasks
  missedTasks: MissedTasks
}>

const keyExtractor = (item?: PatientListData) =>
  item?.patient?.consultation_id ?? ''

const FullScreenButton: React.FC<{
  onPress: () => void
  isFullScreen: boolean
}> = React.memo(({ onPress, isFullScreen }) => (
  <TouchableOpacity style={styles.button} onPress={onPress}>
    {isFullScreen ? <SvgExitFull /> : <SvgEnterFull />}
  </TouchableOpacity>
))

type GetShouldFetchMore = (checks: {
  hasPIMSIntegration: null | boolean
  loading: boolean
  searchQuery: string
  nextCursor: string
  listView: PatientListView
}) => boolean

export const getShouldFetchMore: GetShouldFetchMore = ({
  hasPIMSIntegration,
  loading,
  searchQuery,
  nextCursor,
  listView,
}) =>
  !!hasPIMSIntegration &&
  !loading &&
  !searchQuery && // trigger fetchMore with a search would cycle through all results
  !!nextCursor &&
  !listView.fetchMoreExhausted &&
  !listView.fetchedCursors?.includes(nextCursor)

type GetMoreItems = (args: {
  addCursorBeforeFetch: (cursor: string) => void
  checkExhaustedAfterFetch: (hasPatientLength: boolean) => void
  doFetchMore: DoFetchMore
  nextCursor: string
}) => Promise<void>

export const getMoreItems: GetMoreItems = async ({
  addCursorBeforeFetch,
  checkExhaustedAfterFetch,
  doFetchMore,
  nextCursor,
}) => {
  addCursorBeforeFetch(nextCursor)
  const fetchMoreResult = await doFetchMore(nextCursor)
  if (!fetchMoreResult) return

  const { data: fetchMoreData } = fetchMoreResult
  let hasMorePatient = false
  if ('listWhiteboardPatients' in fetchMoreData) {
    hasMorePatient = !!fetchMoreData.listWhiteboardPatients.items?.length
  } else if ('listWhiteboardWorkflows' in fetchMoreData) {
    hasMorePatient = !!fetchMoreData.listWhiteboardWorkflows.items.length
  }
  checkExhaustedAfterFetch(hasMorePatient)
}

export const PatientList: React.FC = () => {
  const { isLargeScreen, isMediumScreen, isExSmallScreen, isSmallScreen } =
    useBreakpoint()
  const isXSOrSmallScreen = isExSmallScreen || isSmallScreen
  const isIOS = Platform.OS === 'ios'
  const shouldDisplayEmergBtn = isXSOrSmallScreen || isMediumScreen || isIOS
  const [{ hasPIMSIntegration }] = useOrganisation()
  const { navigate } = useNavigation()
  const { t } = useTranslation()
  const searchText = isXSOrSmallScreen
    ? t('patient:list.searchShort')
    : t('patient:list.searchLong')

  const { filterState, setFilterState, clearFilters } =
    useLocalStorageChangeFilters(filterType.Patient)

  const { isTrainingOrganisation } = useTrainingSwitch()

  const { handleChangeSortOption, selectedSortOption, sortPageInput } =
    useLocalStorageChangeSort(sortType.Patient)

  const columns = usePatientTasksColumns()

  const isSortByPatientOrder =
    selectedSortOption === SortOptionsEnum.SortByOrderAsc ||
    selectedSortOption === SortOptionsEnum.SortByOrderDesc

  const {
    items: patientsWithTasks,
    doFetchMore,
    loading,
    pollVisibleCursors,
    onRefresh,
    nextCursor,
    queryTimeStampsRef,
  } = useGetPatientList({
    columns,
    sortPageInput,
    filters: filterState,
  })

  const {
    listView,
    resetListView,
    handleViewableItemsChanged,
    addCursorBeforeFetch,
    checkExhaustedAfterFetch,
  } = usePatientListCursor()

  const { scrollListRef, moveToTop, onScroll, onLayout, headerHeightRef } =
    useAutoScroll(listView.fetchMoreExhausted)

  useEffect(() => {
    // reset state and cursor timestamps when column numbers change (page resize)
    resetListView()
    queryTimeStampsRef.current = {}
    // BM: :WARNING: If filterState object is changing often, will cause max out of
    // useEffect recalls, make sure is memo'd :WARNING:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, filterState, sortPageInput])

  usePatientSubscription()

  const { isFullScreen, switchFullScreen } = useHeaderSwitch()

  useEffect(() => {
    // when searching, never trigger fetch more will cycle through all results
    const shouldPollVisibleCursors =
      !loading &&
      !!listView.visiblePageCursors.length &&
      !filterState.searchQuery // no polling when running search. TODO: discuss

    if (!shouldPollVisibleCursors) return

    // setInterval based fetchmore based on visible section(s) of page
    const timeoutIds = pollVisibleCursors(listView.visiblePageCursors)
    return () => timeoutIds.forEach(clearInterval)
  }, [
    filterState.searchQuery,
    listView.visiblePageCursors,
    loading,
    pollVisibleCursors,
  ])

  const isAdding = patientsWithTasks.some(visiblePatient =>
    isOptimisticId(visiblePatient.patient.id),
  )

  const renderListFooter = useCallback(() => {
    // Show loading footer when fetching more
    if (loading) {
      return (
        <ActivityIndicator
          accessibilityLabel="Patient List Loading Indicator"
          size="large"
          style={styles.spinner}
        />
      )
    }
    const hasNoSearchResult =
      (!!filterState.searchQuery ||
        filterState.locations.length !== 0 ||
        filterState.attendingVets.length !== 0 ||
        filterState.attendingVetTechs.length !== 0 ||
        filterState.approvalStatuses.length !== 0 ||
        filterState.sites.length !== 0) &&
      patientsWithTasks.length === 0

    if (hasNoSearchResult) {
      return (
        <Text style={styles.noResultText}>
          {t('patient:list.noSearchResult')}
        </Text>
      )
    }

    return null
  }, [loading, filterState, patientsWithTasks, t])

  const onPressPatientItem = useCallback(
    ({ patientId, sheetId, sheetName }: OnPressPatientListCardParams) => {
      // remove full screen mode when user navigate a patient
      if (isFullScreen) {
        switchFullScreen()
      }

      const sheetIdAndName = sheetId ? { sheetId, sheetName } : {}

      const params = {
        patientId,
        isFromWhiteboard: false,
        initialDateInView: undefined,
        ...sheetIdAndName,
      }

      const screen = sheetId ? Routes.Sheet : Routes.SheetList
      navigate(screen, params)
    },
    [isFullScreen, switchFullScreen, navigate],
  )

  const actionButton = useMemo(
    () =>
      !hasPIMSIntegration && !isTrainingOrganisation
        ? getPatientListActionButton({
            isAdding,
            navigate,
          })
        : null,
    [hasPIMSIntegration, isAdding, navigate, isTrainingOrganisation],
  )

  const firstEndReachedRef = useRef(false)
  const listHeightRef = useRef(0)

  const setListHeightRef = useCallback((height: number) => {
    listHeightRef.current = height
  }, [])

  // Run the fetch of the next section of the list once near end
  const onEndReached = useCallback(() => {
    const shouldFetchMore = getShouldFetchMore({
      hasPIMSIntegration,
      listView,
      loading,
      nextCursor,
      searchQuery: filterState.searchQuery,
    })

    if (!shouldFetchMore) return
    firstEndReachedRef.current = true
    getMoreItems({
      addCursorBeforeFetch,
      checkExhaustedAfterFetch,
      doFetchMore,
      nextCursor,
    })
  }, [
    addCursorBeforeFetch,
    checkExhaustedAfterFetch,
    doFetchMore,
    filterState.searchQuery,
    hasPIMSIntegration,
    listView,
    loading,
    nextCursor,
  ])

  useEffect(() => {
    if (firstEndReachedRef.current) {
      return
    }

    if (
      listHeightRef.current >
        PATIENT_CARD_HEIGHT * PATIENT_LIST_RESULTS_LIMIT &&
      nextCursor
    ) {
      firstEndReachedRef.current = true
      onEndReached()
    }
  }, [nextCursor, onEndReached])

  const sectionListRef = useRef<any>(null)

  const handleFullScreen = useCallback(() => {
    if (filterState.searchQuery) {
      setFilterState.searchQuery('')
    }
    if (!isFullScreen) {
      moveToTop()
    }
    switchFullScreen()
  }, [
    filterState.searchQuery,
    isFullScreen,
    moveToTop,
    switchFullScreen,
    setFilterState,
  ])

  const bottomSheetRef = useRef<BottomSheetModal>(null)
  const [selectedPatientItem, setSelectedPatientItem] = useState<Patient>()
  const handleOnPress = useCallback(
    (patient: Patient) => {
      setSelectedPatientItem(patient)
      bottomSheetRef.current?.present()
    },
    [bottomSheetRef],
  )

  const [showImageUploader, setShowImageUploader] = useState(false)
  const newObjectKeyRef = useRef<string | null>(null)
  const { handlePatientImageUpdate, objectKeyPrefix } =
    useUpdatePatientImage(selectedPatientItem)

  const renderPatientItem = useCallback(
    ({ item }: ListRenderItemInfo<PatientListData>) => (
      <PatientListCard
        onPress={onPressPatientItem}
        item={item}
        isSortByPatientOrder={isSortByPatientOrder}
        onPressKebab={handleOnPress}
      />
    ),
    [onPressPatientItem, isSortByPatientOrder, handleOnPress],
  )

  const sectionData = useMemo(() => {
    // Added sorting to patientsWithTasks when order sorting is applied in the filter
    // Without this, the section list will not be sorted by order on any update via sheet actions
    if (isSortByPatientOrder) {
      patientsWithTasks.sort((a, b) => {
        const [orderA, orderB] = [a.patient.order, b.patient.order]

        if (orderA === null && orderB === null) return 0
        if (orderA === null) return 1
        if (orderB === null) return -1

        // If the order is the same, will sort by the patient name
        if (orderA === orderB) {
          return a.patient.name!.localeCompare(b.patient.name!)
        }

        return selectedSortOption === SortOptionsEnum.SortByOrderAsc
          ? orderA - orderB
          : orderB - orderA
      })
    }

    const [activePatients, _dischargedPatients] = partition(
      patientsWithTasks,
      patientListItem => patientListItem.patient.isActive,
    )
    const dischargedPatients = _dischargedPatients.slice(0, 200)

    return [
      {
        title: t('patient:list.activePatients'),
        data: activePatients,
      },
      {
        title: t('patient:list.dischargedPatients'),
        data: !filterState.searchQuery ? [] : dischargedPatients,
      },
    ]
  }, [
    filterState.searchQuery,
    isSortByPatientOrder,
    patientsWithTasks,
    selectedSortOption,
    t,
  ])

  const renderSectionHeader = useCallback(
    ({ section }: { section: HeaderSectionList }) => {
      if (!section.data.length) return null
      return (
        <View
          style={styles.sectionHeader}
          onLayout={e => {
            headerHeightRef.current =
              e.nativeEvent.layout.height - HEADER_ERROR_HEIGHT
          }}
        >
          {/* Only show title if there are discharged section */}
          {!!sectionData[1].data.length && (
            <Text style={styles.title}>
              {`${section.title}(${section.data.length})`}
            </Text>
          )}
          {!!patientsWithTasks.length && (
            <PatientListHeader tasks={patientsWithTasks[0].tasks} />
          )}
        </View>
      )
    },
    [patientsWithTasks, sectionData, headerHeightRef],
  )

  const [refreshing, setRefreshing] = React.useState(false)
  const onPullRefresh = React.useCallback(async () => {
    try {
      setRefreshing(true)
      await onRefresh(listView.fetchedCursors)
    } finally {
      setRefreshing(false)
    }
  }, [onRefresh, listView.fetchedCursors])

  const refreshControl = useMemo(
    () => (
      <RefreshControl
        accessibilityLabel={'Refresh Control'}
        refreshing={refreshing}
        onRefresh={onPullRefresh}
      />
    ),
    [onPullRefresh, refreshing],
  )
  const handleSearchChange = useCallback(
    (text: string) => {
      // remove quotation marks
      setFilterState.searchQuery(text.replace(/["']/g, ''))
    },
    [setFilterState],
  )

  const onSectionListLayout = useCallback(
    (e: LayoutChangeEvent) => {
      setListHeightRef(e?.nativeEvent?.layout?.height)
      scrollListRef.current =
        sectionListRef.current?._wrapperListRef?.getListRef()
      onLayout(e)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onLayout],
  )

  const bottomSheetDismiss = () => {
    bottomSheetRef.current?.dismiss()
  }

  return (
    // TODO: Remove <View> styling once appscreen.web and navigationstack styling applied
    // TODO: Is ExpirationPopup best here? Or can we find a higher component still inside the navigator
    <View style={styles.whiteBgContainer}>
      <ExpirationPopup />
      {!isFullScreen && (
        <SubHeader
          headlineKey="title.patients"
          actionButton={actionButton}
          showEmergButton={shouldDisplayEmergBtn}
        />
      )}
      <View style={styles.filterContainer}>
        <View style={styles.filterWrap}>
          {isFullScreen ? (
            <Clock />
          ) : (
            <SearchInput
              placeholder={searchText}
              onChangeText={handleSearchChange}
              style={[styles.searchInput]}
              wait={500}
              showClearButton={true}
              testID="SearchPatient"
            />
          )}
          {isLargeScreen ? (
            <FilterClearButtons
              setFilterState={setFilterState}
              filterState={filterState}
            />
          ) : null}
          <View style={styles.filter}>
            <PatientListSort
              onSelectSort={handleChangeSortOption}
              selectedSort={selectedSortOption}
            />
            <PatientListFilter
              onSelectDepartment={setFilterState.sites}
              onSelectLocation={setFilterState.locations}
              onSelectVet={setFilterState.attendingVets}
              onSelectVetTech={setFilterState.attendingVetTechs}
              onSelectApprovalStatus={setFilterState.approvalStatuses}
              selectedApprovalStatuses={filterState.approvalStatuses}
              selectedDepartments={filterState.sites}
              selectedLocations={filterState.locations}
              selectedVets={filterState.attendingVets}
              selectedVetTechs={filterState.attendingVetTechs}
              clearFilters={clearFilters}
              setDoctors={setFilterState.doctorList}
              setVetTechs={setFilterState.vetTechList}
              setDepartments={setFilterState.departmentList}
              setLocations={setFilterState.locationList}
            />
            {!isXSOrSmallScreen && (
              <FullScreenButton
                isFullScreen={isFullScreen}
                onPress={handleFullScreen}
              />
            )}
          </View>
        </View>
        {!isLargeScreen && (
          <FilterClearButtons
            setFilterState={setFilterState}
            filterState={filterState}
          />
        )}
      </View>
      <VRBottomSheet
        title={`"${selectedPatientItem?.name}" ${selectedPatientItem?.contact?.last_name}`}
        ref={bottomSheetRef}
        handleClose={bottomSheetDismiss}
      >
        {selectedPatientItem ? (
          <SheetActionContainer
            patient={selectedPatientItem}
            consultationId={selectedPatientItem?.consultation_id}
            locations={selectedPatientItem?.consultation_locations}
            currentColor={selectedPatientItem?.consultation_color}
            currentCPRStatus={selectedPatientItem?.resuscitate}
            onClose={bottomSheetDismiss}
            handlePatientImage={() => setShowImageUploader(true)}
          />
        ) : null}
      </VRBottomSheet>
      <Pressable onPress={preventClickPropagation}>
        <ImageUploaderDialog
          visible={showImageUploader}
          toggleDialog={() => setShowImageUploader(false)}
          objectKey={selectedPatientItem?.avatar_url!}
          objectKeyPrefix={objectKeyPrefix}
          onChange={v =>
            handlePatientImageUpdate(v, () => {
              newObjectKeyRef.current = null
            })
          }
          newObjectKeyRef={newObjectKeyRef}
          noImageText={t('patient:view:noImageText')}
        />
      </Pressable>
      <SectionList
        onScroll={onScroll}
        onLayout={onSectionListLayout}
        ref={sectionListRef}
        contentContainerStyle={styles.patientContainer}
        keyExtractor={keyExtractor}
        ListFooterComponent={renderListFooter}
        onEndReached={onEndReached}
        onEndReachedThreshold={SCROLL_LIST_END_REACHED_THRESHOLD}
        onViewableItemsChanged={handleViewableItemsChanged}
        refreshControl={refreshControl}
        renderItem={renderPatientItem}
        renderSectionHeader={renderSectionHeader}
        sections={sectionData}
        stickySectionHeadersEnabled={true}
        testID="PatientList"
      />
    </View>
  )
}

const styles = StyleSheet.create({
  patientContainer: {
    paddingBottom: 16,
  },
  spinner: {
    marginTop: 25,
  },
  whiteBgContainer: {
    backgroundColor: 'white',
    height: '100%',
  },
  filterContainer: {
    paddingBottom: 8,
    paddingHorizontal: 15,
  },
  filterWrap: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 12,
  },
  sectionHeader: {
    backgroundColor: 'white',
    marginTop: -2,
  },
  searchInput: {
    width: '45%',
    borderColor: Colors.lightGrey,
    borderBottomWidth: 1,
  },
  filter: {
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginLeft: 15,
    fontSize: 24,
    fontFamily: Fonts.bold,
  },
  button: {
    flexDirection: 'row',
    marginLeft: 24,
    width: 44,
    height: 44,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: Colors.contentSecondary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noResultText: {
    textAlign: 'center',
    marginHorizontal: 60,
    fontFamily: Fonts.regular,
    fontSize: 15,
    color: '#3d3d3d',
  },
})
