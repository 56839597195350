import React, { PropsWithChildren } from 'react'
import {
  View,
  Image,
  StyleSheet,
  StatusBar,
  ViewProps,
  Platform,
} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Colors } from 'src/design-system/theme'
import { useBreakpoint, withBreakpointProvider } from 'src/hocs/breakpoint'

const InnerLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isVeryLargeScreen, isExSmallScreen } = useBreakpoint()

  if (isVeryLargeScreen) {
    return (
      <Container>
        <Content>
          <View style={styles.childrenContainer}>{children}</View>
        </Content>
        <View style={styles.heroContainer}>
          <Image
            source={require('assets/images/dog-on-rocket.png')}
            resizeMode="contain"
            style={styles.heroImage}
          />
        </View>
      </Container>
    )
  }

  if (!isExSmallScreen) {
    return (
      <Container>
        <Content style={styles.contentMedium}>
          <View style={styles.childrenContainer}>{children}</View>
        </Content>
      </Container>
    )
  }

  return (
    <Container>
      <Content style={styles.contentSmall}>
        <View style={styles.childrenContainer}>{children}</View>
      </Content>
    </Container>
  )
}

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  if (Platform.OS === 'web') {
    return <View style={styles.container}>{children}</View>
  }
  return (
    <>
      <StatusBar barStyle="dark-content" />
      <View style={styles.container}>{children}</View>
    </>
  )
}

const Content: React.FC<PropsWithChildren<ViewProps>> = ({
  children,
  style,
}) => (
  <KeyboardAwareScrollView
    style={{ padding: 24 }}
    contentContainerStyle={[styles.content, style]}
  >
    {children}
  </KeyboardAwareScrollView>
)

export const Layout = withBreakpointProvider(InnerLayout)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.Backgrounds.UI,
  },
  heroContainer: {
    flex: 1,
    backgroundColor: '#1F284D',
    justifyContent: 'center',
  },
  heroImage: {
    flex: 1,
    maxHeight: 400,
  },
  content: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentMedium: {
    padding: 24,
  },
  contentSmall: {
    paddingVertical: 56,
    paddingHorizontal: 24,
  },
  childrenContainer: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 504,
  },
})
