import React from 'react'

import Svg, { Path, Circle } from 'react-native-svg'

export const EmailUnverified: React.FC = () => (
  <Svg width="22" height="16" viewBox="0 0 22 16" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.05714C1 1.92101 1.92101 1 3.05714 1H15.4C16.5361 1 17.4572 1.92101 17.4572 3.05714V12.6571C17.4572 13.7933 16.5361 14.7143 15.4 14.7143H3.05714C1.92101 14.7143 1 13.7933 1 12.6571V3.05714Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.64548 2.57573L2.56801 1.56096L9.22858 7.61614L15.8885 1.56164L16.811 2.57641L9.22857 9.46958L1.64548 2.57573Z"
      fill="#A9AAAA"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.05714 2.37143C2.67843 2.37143 2.37143 2.67843 2.37143 3.05714V12.6571C2.37143 13.0359 2.67843 13.3429 3.05714 13.3429H15.4C15.7787 13.3429 16.0857 13.0359 16.0857 12.6571V3.05714C16.0857 2.67843 15.7787 2.37143 15.4 2.37143H3.05714ZM1 3.05714C1 1.92101 1.92101 1 3.05714 1H15.4C16.5361 1 17.4572 1.92101 17.4572 3.05714V12.6571C17.4572 13.7933 16.5361 14.7143 15.4 14.7143H3.05714C1.92101 14.7143 1 13.7933 1 12.6571V3.05714Z"
      fill="#A9AAAA"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.64548 2.57573L2.56801 1.56096L9.22858 7.61614L15.8885 1.56164L16.811 2.57641L9.22857 9.46958L1.64548 2.57573Z"
      stroke="#A9AAAA"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.05714 2.37143C2.67843 2.37143 2.37143 2.67843 2.37143 3.05714V12.6571C2.37143 13.0359 2.67843 13.3429 3.05714 13.3429H15.4C15.7787 13.3429 16.0857 13.0359 16.0857 12.6571V3.05714C16.0857 2.67843 15.7787 2.37143 15.4 2.37143H3.05714ZM1 3.05714C1 1.92101 1.92101 1 3.05714 1H15.4C16.5361 1 17.4572 1.92101 17.4572 3.05714V12.6571C17.4572 13.7933 16.5361 14.7143 15.4 14.7143H3.05714C1.92101 14.7143 1 13.7933 1 12.6571V3.05714Z"
      stroke="#A9AAAA"
      strokeWidth="0.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <Circle cx="16" cy="10" r="5.5" fill="white" stroke="white" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4226 8.16667L14.1666 12.4226L13.5774 11.8333L17.8333 7.57742L18.4226 8.16667Z"
      fill="#A9AAAA"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1666 7.57742L18.4226 11.8333L17.8333 12.4226L13.5774 8.16667L14.1666 7.57742Z"
      fill="#A9AAAA"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.83333C13.6988 5.83333 11.8333 7.69881 11.8333 10C11.8333 12.3012 13.6988 14.1667 16 14.1667C18.3012 14.1667 20.1667 12.3012 20.1667 10C20.1667 7.69881 18.3012 5.83333 16 5.83333ZM11 10C11 7.23858 13.2386 5 16 5C18.7614 5 21 7.23858 21 10C21 12.7614 18.7614 15 16 15C13.2386 15 11 12.7614 11 10Z"
      fill="#A9AAAA"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4226 8.16667L14.1666 12.4226L13.5774 11.8333L17.8333 7.57742L18.4226 8.16667Z"
      stroke="#A9AAAA"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1666 7.57742L18.4226 11.8333L17.8333 12.4226L13.5774 8.16667L14.1666 7.57742Z"
      stroke="#A9AAAA"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 5.83333C13.6988 5.83333 11.8333 7.69881 11.8333 10C11.8333 12.3012 13.6988 14.1667 16 14.1667C18.3012 14.1667 20.1667 12.3012 20.1667 10C20.1667 7.69881 18.3012 5.83333 16 5.83333ZM11 10C11 7.23858 13.2386 5 16 5C18.7614 5 21 7.23858 21 10C21 12.7614 18.7614 15 16 15C13.2386 15 11 12.7614 11 10Z"
      stroke="#A9AAAA"
      strokeWidth="0.75"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </Svg>
)
